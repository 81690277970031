#map-popup-scroll-top-button {
    position: absolute;
    top: 15px;
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    z-index: 9999;
}

div.map-popup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    max-height: 60vh;
    max-width: 600px;
    overflow-x: auto;
    scrollbar-width: 0;
    border-radius: 10px;
}

div.map-popup-container::-webkit-scrollbar {
    width: 0px;
}

div.map-popup-container-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    color: #ffff;
    gap: 5px;
}

div.map-popup-container-header .map-popup-header-stat-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    text-align: center;
    padding: 6px 10px;
    border-radius: 10px;
    background: #000a;
    max-width: 160px;
}

div.map-popup-container-header .map-popup-header-heading {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    text-align: center;
    flex-direction: row;
    padding: 6px 10px;
    border-radius: 10px;
    background: #000a;
    min-width: 100%;
}

div.map-popup-container-header .map-popup-header-heading img {
    width: 32px;
    height: 32px;
}

div.map-popup-container-header .map-popup-header-heading h2 {
    font-size: 1.1em;
    padding: 0;
    margin: 0;
    padding-top: 2px;
    margin-left: 5px;
}

div.map-popup-container-header .map-popup-header-tariff {
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
}

div.map-popup-container-header .map-popup-header-tariff>span:first-of-type {
    font-size: 0.9rem;
    font-weight: bold;
}

div.map-popup-container-header .map-popup-header-tariff a {
    cursor: pointer;
}

div.map-popup-container-header .map-popup-header-stat-key div:first-of-type {
    font-size: 0.7rem;
    font-weight: bold;
}

div.map-popup-container-header div.map-popup-header-stat-separator {
    border-top: dashed white 1px;
    width: 90%;
    margin-top: 8px;
    padding-bottom: 8px;
}

div.map-popup-container-header .map-popup-header-stat-value div:first-of-type {
    font-weight: bold;
    font-size: 1.3rem;
}

div.map-popup-container-header .map-popup-header-stat-value.connector-available div:first-of-type {
    color: #5da55d;
}

div.map-popup-container-header .map-popup-header-stat-value.connector-unavailable div:first-of-type {
    color: #bb4e4e;
}

div.map-popup-container-header .map-popup-header-stat-value div:nth-child(2) {
    font-size: 0.75rem;
}

.map-popup-container-expand-button {
    max-height: 35px;
    min-height: 35px;
}

div.map-popup-container-device {
    background: #000a;
    width: 100%;
    color: #ffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-bottom: 0;
}

div.map-popup-container-device h3,
div.map-popup-container-device h4 {
    margin: 0;
    padding: 0;
    text-align: center;
}

div.map-popup-container-device h3 {
    font-size: 1rem;
    font-weight: bold;
}

div.map-popup-container-device h4 {
    font-size: 0.9rem;
}

div.map-popup-container-tariff {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-self: center;
    width: 100%;
    max-width: 370px;
    padding: 6px;
    margin-top: 6px;
    background: #fff2;
    color: #ffff;
    border: 2px solid #fff5;
    border-radius: 15px;
    text-align: justify;
}

div.map-popup-container-tariff-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    flex: 1 1;
    padding: 5px 8px;
    text-align: center;
}

div.map-popup-container-tariff-item>span:first-of-type {
    font-size: 0.9rem;
    font-weight: bold;
}

div.map-popup-container-tariff-item>span:last-of-type {
    font-size: 0.8rem;
}

div.map-popup-container-connectors {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: center;
    justify-content: center;
    width: 100%;
    margin: 8px 0;
    gap: 10px;
    font-size: 0.85rem;
}

div.map-popup-container-connector {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    background: #5b67ff69;
    padding: 10px 12px;
    border-radius: 15px;
    max-width: 180px;
    min-width: 180px;
    flex: 1;
    border: 2px #fff5 solid;
}

@media screen and (max-width: 426px) {
    div.map-popup-container-connector {
        width: 100%;
        max-width: unset;
        padding: 8px 12px;
    }
}

div.map-popup-container-connector>* {
    flex: 1;
}

div.map-popup-container-connector>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2px;
}

img.charge-status-type-icon {
    width: 30px;
    height: 30px;
    min-width: 30px;
}

span.charge-status-type-text {
    font-size: 0.7rem;
}

div.charge-status-indicator {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    border: solid #ffff 2px;
}

div.charge-status-occupied .charge-status-indicator {
    background: #4854e0;
}

div.charge-status-available .charge-status-indicator {
    background: #24a357;
}

div.charge-status-unavailable .charge-status-indicator {
    background: #f00;
}

div.charge-status-unknown .charge-status-indicator {
    background: grey;
}