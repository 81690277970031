img#header-logo {
    background: #fff2;
    height: 3rem;
    padding: 5px 15px;
    border-radius: 10px;
    margin: 5px auto;
    cursor: pointer;
    display: flex;
}

ion-content {
    --overflow: hidden;
}

div#map-loading-container {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    z-index: 999;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
    transition: 0.5s opacity;
    user-select: none;
}

div#map-loading-container.map-loading-container-hidden {
    opacity: 0;
    pointer-events: none;
}

div#map-loading-container>ion-spinner {
    height: 90px;
    width: 90px;
}

div#map-loading-container>ion-label {
    font-size: 1.5rem;
    color: #ffff;
}

div#map-loading-progress-container {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 3px;
    background: #fff3;
    padding: 15px;
    border-radius: 15px;
    margin-top: 30px;
}

div.map-loading-progress-item {
    display: flex;
    align-items: center;
    gap: 5px;
}

div.map-loading-progress-item ion-icon {
    font-size: 1.5rem;
    color: #000a;
}

div.map-loading-progress-item.map-loading-progress-item-success ion-icon {
    color: green;
}

div#map {
    width: 100%;
    height: 100%;
    transition: 1s opacity;
}

div#map.map-hidden {
    opacity: 0;
    visibility: none;
}

div#map .mapboxgl-canvas-container {
    transition: 0.5s filter;
}

div#map.map-obscured .mapboxgl-canvas-container {
    filter: blur(3px);
}

.map-popup {
    z-index: 2;
}

.map-popup .mapboxgl-popup-tip {
    border-bottom-color: #fff4 !important;
    backdrop-filter: blur(5px) !important;
}

.map-popup .mapboxgl-popup-content {
    padding: 8px;
    background: #fff4;
    border-radius: 15px;
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    margin: 0 10px;
    backdrop-filter: blur(5px);
}